.display-section {
    background-color: #fff;
    height: 65vh;
    max-height: 50rem;
    margin-top: 2.5rem;
    border-radius: 2rem;
    box-shadow: $standard-box-shadow;
    max-width: $max-component-width;
    left: 50%;
    transform: translateX(-50%);
    position: relative;
    transition: all 2s;

    &--appearing {
        opacity: 1;
    }

    &--disappearing {
        opacity: 0;
    }

    &--past {
        filter: grayscale(.5);
        box-shadow: $past-box-shadow;
        background-color: rgb(230, 230, 230);
    }

    &--future {
        filter: blur(.8px);
        box-shadow: $future-box-shadow;
    }

    .display-section__body {
        height: inherit;
        max-height: inherit;
        width: inherit;
        display: flex;

        .display-section__iconMenu {
            position: absolute;
            display: grid;
            grid-template-columns: 6rem 6rem 6rem;
            grid-template-rows: 6rem 6rem 6rem;
            grid-template-areas: 
                ". . overview"
                ". education work"
                "home projects location"
            ;
            grid-gap: 1.5rem;
            right: 50%;
            bottom: 1.5rem;
            text-align: center;

            .menuElement:not(img) {
                padding-top: .3rem;
                font-size: 4rem;
                background: linear-gradient(to bottom right, black, skyblue);
                background-clip: text;
                color: transparent;
                opacity: .4;
                transition: font-size .3s, opacity 1s;

                &.blinking {
                    border-radius: 50px;
                    padding-top: 10px;
                    bottom: 5px;
                    position: relative;
                    animation-name: lightbulb-blip;
                    animation-timing-function: linear;
                    animation-duration: 3s;
                    animation-iteration-count: infinite;
                }
            }

            .menuElement:not(.active):hover {
                opacity: 1;
                font-size: 5.25rem;
                cursor: pointer;
            }

            .menuElement:not(.active).blinking:hover, 
            .menuElement.active.blinking {
                padding-top: 0;
                bottom: 0;
                animation-name: none;
                background-clip: text;
            }

            .active {
                border-radius: 5rem;
                font-size: 3.25rem !important;
                opacity: 1 !important;
                background-clip: unset !important;
                animation-name: none;

                &::before {
                    color: white;
                    position: relative;
                    top: 18%;
                }
            }

            .active.blinking::before {
                top: 22%;
            }

            img {
                width: 5rem;
                transition: width .3s;

                &:hover {
                    width: 6.25rem;
                    cursor: pointer;
                }
            }

            #icon-overview {
                grid-area: overview;
            }
            #icon-education {
                grid-area: education;
            }
            #icon-work {
                grid-area: work;
            }
            #icon-home {
                grid-area: home;
                margin: 0 auto;
            }
            #icon-projects {
                grid-area: projects;
            }
            #icon-location {
                grid-area: location;
            }
        }

        .display-section__half {
            height: inherit;
            width: 50%;
            color: black;
        }

        .display-section__left {
            height: 95%;
            max-height: inherit;
            border-radius: 2rem;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            clip-path: polygon(0 0, 100% 0, 50% 100%, 0 100%);
            margin: 10px;
            transition: all 2s;

            &--past {
                filter: grayscale(.9); 
            }
            &--future {
                filter: blur(2px); 
            }

            &--home--home {
                background-image: url("./../../images/david.jpg");
            }

            &--education--criminology {
                background-image: url("./../../images/criminology.jpg");
            }

            &--education--tourism {
                background-image: url("./../../images/tourism.jpg");
            }

            &--education--social-media {
                background-image: url("./../../images/social-media.jpg");
            }

            &--education--coding {
                background-image: url("./../../images/coding.jpg");
            }

            &--education--webcourses {
                background-image: url("./../../images/more-coding.jpg");
            }

            &--education--future-coding {
                background-image: url("./../../images/motherboard.jpg");
            }

            &--work--supermarket {
                background-image: url("./../../images/supermarket.jpg");
            }

            &--work--lifeguard {
                background-image: url("./../../images/lifeguard.jpg");
            }

            &--work--kitchen {
                background-image: url("./../../images/kitchen.jpg");
            }

            &--work--waiter {
                background-image: url("./../../images/waiter.jpg");
            }

            &--work--handling {
                background-image: url("./../../images/handling.jpg");
            }

            &--work--call-center {
                background-image: url("./../../images/call-center.jpg");
            }

            &--work--hotel {
                background-image: url("./../../images/hotel.jpg");
            }

            &--work--control {
                background-image: url("./../../images/control.jpg");
            }

            &--work--atoom {
                background-image: url("./../../images/gambling.jpg");
            }
            &--work--next-job {
                background-image: url("./../../images/working-cat.jpg");
            }

            &--projects--appventure {
                background-image: url("./../../images/appventure-game.jpg");
                background-position-x: -10rem;
                background-color: #fcfbfb;
            }

            &--projects--dreambig {
                background-image: url("./../../images/cambodia.jpg");
            }

            &--projects--covid {
                background-image: url("./../../images/covid-invaders.jpg");
                background-position-y: 0;
            }

            &--projects--phone {
                background-image: url("./../../images/kiwi-phone.jpg");
                background-position-x: 0;
            }

            &--projects--calculator {
                background-color: black;
                background-image: url("./../../images/calculator.jpg");
                background-position-x: -5rem;
            }

            &--projects--memory {
                background-image: url("./../../images/dragonball.jpg");
                background-position-x: -34rem;
            }

            &--projects--spaceline {
                background-image: url("./../../images/spaceline.jpg");
            }

            &--location--barcelona {
                background-image: url("./../../images/barcelona.jpg");
            }

            &--location--manchester {
                background-image: url("./../../images/manchester.jpg");
            }
    
            &--location--dubai {
                background-image: url("./../../images/dubai.jpg");
            }
    
            &--location--world {
                background-image: url("./../../images/world.jpg");
            }

            &--location--space {
                background-image: url("./../../images/mars.jpg");
            }
        }

        .display-section__right {
            position: relative;
            max-height: inherit;
            padding: 5rem;
            font-size: 1.5rem;
            overflow: auto;

            &::-webkit-scrollbar {
                background-color: transparent;
                width: .7rem;

                &:hover {
                    cursor: pointer;
                }
            }

            &::-webkit-scrollbar-thumb {
                background: linear-gradient(to bottom, transparent, white, black, skyblue, white, transparent);
                border-radius: 1rem;
            }

            .display-section__textBox {
                position: relative;

                ::selection {
                    background: rgb(72, 118, 137);
                    color: white;
                }
                
                .display-section__title {
                    font-size: 2.5rem;
                    transition: all 2s;

                    &--smaller {
                        font-size: 1.7rem;
                    }

                    &--red a:link, 
                    &--red a:visited, 
                    &--red a:hover, 
                    &--red a:active {
                        text-decoration: none;
                        color: red;
                    }
                }

                .display-section__subtitle {
                    font-size: 1.5rem;
                    font-style: italic;
                }

                .display-section__prompt {
                    margin: 2rem auto 0;
                    font-size: 2rem;
                    font-style: italic;
                    color: rgb(72, 118, 137);
                    text-transform: uppercase;
                    animation-name: prompt-blip;
                    animation-duration: 1.5s;
                    animation-timing-function: linear;
                    animation-iteration-count: infinite;

                    &--big {
                        font-size: 3rem;
                    }
                }

                .display-section__link {
                    text-decoration: none;
                }

                .display-section__bulletList {
                    padding-left: 4rem;

                    &--blip {
                        animation-name: elm-blip;
                        animation-duration: 5s;
                        animation-timing-function: linear;
                        animation-iteration-count: infinite;
                    }

                    &--proscons {
                        padding-left: 2rem;
                        margin-top: 2rem;
                    }
                }

                .display-section__langBox {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 25%;
                    margin: 0 auto;

                    .display-section__langIcon {
                        width: 5rem;
                    }

                    &--wide {
                        width: 80%;
                        margin-top: 2rem;

                        .display-section__langIcon {
                            width: 7rem;
                        }
                    }
                }

                .display-section__langBox--manchester {
                    justify-content: center;
                }

                .display-section__logoBox {
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    width: 100%;
                    word-break: break-word;
                    position: relative;

                    &:first-child {
                        margin: 3rem auto 0;
                    }

                    &.org-name-wrapper {
                        height: 20rem;
                        margin: 6rem auto 0;
                    }

                    .display-section__logoItem {
                        width: 10rem;

                        &--big {
                            width: 13rem;
                        }

                        &--extra-big {
                            width: 20rem;
                        }

                        &--huge {
                            width: 30rem;
                        }

                        &--rounded {
                            border-radius: 2.5rem;
                        }

                        &--code {
                            display: block;
                            margin: 0 auto;
                        }
                    }

                    .display-section__snapshot {
                        text-decoration: none;
                        display: block;
                        text-align: center;
                        
                        & img:not(.square) {
                            border-radius: 20px;
                        }

                        &:link,
                        &:visited {
                            color: black;
                        }

                        &:hover,
                        &:active {
                            color: red;
                            text-shadow: 0 1rem 2rem darkred;
                        }

                        &:hover > img {
                            animation-name: catbtn-blip;
                            animation-duration: 3.5s;
                            animation-timing-function: linear;
                            animation-iteration-count: infinite;
                        }

                        .try-prompt {
                            text-align: center;
                            margin-top: 1rem;
                            font-size: 2rem;
                            transition: all .5s;
                        }
                    }

                    .beating-title {
                        font-size: 4rem;
                        color: darkred;
                        text-align: center;
                        z-index: -1;
                        animation-name: title-beat;
                        animation-duration: 2s;
                        animation-timing-function: linear;
                        animation-iteration-count: infinite;
                        transition: transform 2s;

                        &--hiding {
                            transform: translateY(10rem);
                        }

                        &--showing {
                            transform: translateY(-10rem);
                        }
                    }

                    .cover-img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -60%);
                        width: 60%;
                        
                        &--sun {
                            z-index: -2;
                        }

                        &:hover {
                            cursor: grab;
                        }
                    }
                }

                .alert {
                    font-size: 2rem;
                    text-align: center;
                    color: darkred;
                    text-transform: uppercase;
                    animation-name: prompt-blip;
                    animation-duration: 1.5s;
                    animation-timing-function: linear;
                    animation-iteration-count: infinite;
                    margin-bottom: 1.5rem;
                }
            }
        }
    }
}
