.advancing-border {
    position: absolute;
    border: 3px solid black;

    &__pointer {
        position: absolute;
        width: 1.25rem;
        height: 1.25rem;
        border-radius: 50%;
        background-color: rgb(250, 235, 125);
        animation-name: laser-pointer-glow;
        animation-duration: 1.5s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        transition: opacity .3s;

        &--top {
            left: -.6rem;
            top: -.5rem;
        }

        &--bottom {
            right: -.6rem;
            bottom: -.5rem;
        }

        &--right {
            right: -.5rem;
            top: -.6rem;
        }

        &--left {
            left: -.5rem;
            top: -.6rem;
        }

        &--true {
            opacity: 1;
        }

        &--false {
            opacity: 0;
        }
    }

    &--top {
        top: 0;
        left: 0;

        &-glowing {
            border: 3px solid red;
            width: 100%;
            animation-name: vertical-laser-box-glow;
            animation-duration: 2s;
            animation-timing-function: linear;
        }
    }

    &--right {
        top: 0;
        right: 0;

        &-glowing {
            border: 3px solid red;
            height: 100%;
            animation-name: horizontal-laser-box-glow;
            animation-duration: 2s;
            animation-timing-function: linear;
        }
    }

    &--bottom {
        bottom: 0;
        right: 0;

        &-glowing {
            border: 3px solid red;
            width: 100%;
            animation-name: vertical-laser-box-glow;
            animation-duration: 2s;
            animation-timing-function: linear;
        }
    }

    &--left {
        bottom: 0;
        left: 0;

        &-glowing {
            border: 3px solid red;
            height: 100%;
            animation-name: horizontal-laser-box-glow;
            animation-duration: 2s;
            animation-timing-function: linear;
        }
    }

    &--invisible {
        width: 0%;
    }

    &--completed-horizontal {
        border: 3px solid red;
        width: 100%;
    }

    &--completed-vertical {
        border: 3px solid red;
        height: 100%;
    }

    &--printing-horizontal {
        border: 3px solid red;
        animation-name: horizontal-laser-draw;
        animation-duration: 2.5s;
        animation-timing-function: linear;
    }

    &--printing-vertical {
        border: 3px solid red;
        animation-name: vertical-laser-draw;
        animation-duration: 2.5s;
        animation-timing-function: linear;
    }
}

.laserbox {
    min-height: 60rem;
    position: relative;

    ::selection {
        background-color: darkred;
        color: white;
    }

    &__content-wrapper {
        padding: 5rem;
        font-size: 1.8rem;
        opacity: 0;
        height: 60rem;

        h2 {
            font-size: 1.8rem;
        }
    
        &--appearing {
            animation-name: content-appearing;
            animation-duration: 3s;
            animation-timing-function: linear;
        }
    
        &--visible {
            opacity: 1;
        }

        &--invisible {
            opacity: 0;
        }

        &--zombiecat {
            padding: 3rem 5rem;
        }

        &--myself {
            padding: 1rem 5rem;

            .u-visible {
                margin-top: 2.5rem;
            }

            .collage__img {
                position: absolute;

                &--center {
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 10;
                }
            }

            .collage__div {
                position: absolute;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;

                &--1 {
                    width: 35%;
                    height: 300px;
                    top: 5rem;
                    left: 5rem;
                    z-index: 8;
                    background-image: linear-gradient(to right, black, transparent 5% 50%, black), linear-gradient(to bottom right, black, transparent 30%, black), linear-gradient(to bottom, black, transparent, black 100%), url("./../../images/pyramid.jpg");
                }

                &--2 {
                    width: 25%;
                    height: 250px;
                    bottom: 7rem;
                    left: 25rem;
                    z-index: 9;
                    background-image: linear-gradient(to right, black, transparent, black), linear-gradient(to bottom, black, transparent, black 100%), url("./../../images/beach.jpg");
                }

                &--3 {
                    width: 40%;
                    height: 275px;
                    top: 4rem;
                    right: 20rem;
                    z-index: 9;
                    background-image: linear-gradient(to right, black, transparent, black), linear-gradient(to bottom, black, transparent, black 100%), url("./../../images/adventure.jpg");
                }

                &--4 {
                    width: 35%;
                    height: 300px;
                    bottom: 5rem;
                    right: 5rem;
                    z-index: 8;
                    background-image: linear-gradient(to right, black, transparent, black), linear-gradient(to bottom, black, transparent, black 100%), url("./../../images/goat.jpg");
                }
            }
        }
    }

    &__content-subwrapper {
        height: 89%;
        overflow: auto;

        &::-webkit-scrollbar {
            background-color: transparent;
            width: .8rem;
        }

        &::-webkit-scrollbar-thumb {
            background: linear-gradient(to bottom, yellow, gold, red, darkred);
            border-radius: 2rem;
        }
    }

    &__content-section {
        background-size: cover;
        background-position: center 40%;
        background-repeat: no-repeat;
        transition: all 2s;

        &--cat {
            background-image: linear-gradient(to right, black, transparent, black), linear-gradient(to bottom, black, transparent, black 100%), url("./../../images/zombi.jpg");
            height: 100%;
        }

        &--cat-stars {
            background-size: contain;
            background-image: url("./../../images/zombi-in-the-stars.png");
            background-position: center;
            height: 100%;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
        }
    }

    &__paragraph {
        &--italic {
            font-style: italic;
            font-size: 1.5rem !important;
        }

        &--small {
            font-size: 1.3rem !important;
        }

        &--huge {
            font-size: 3rem !important;
        }

        &--shiny {
            text-shadow: 
                3rem 1rem 3rem rgba(169, 169, 169, 0.5), 
                -3rem 1rem 3rem rgba(169, 169, 169, 0.5), 
                0 -0.25rem 3rem rgba(169, 169, 169, 0.5);
        }

        &--separated {
            border-top: 1px dashed rgba(255, 255, 255, .5);
            padding-top: 1rem;
        }
    }

    &__chunk > * {
        display: inline;
    }

    &__mention {
        color: white;
        text-decoration: none;
        font-size: 1.5rem;
    }

    &__link > * {
        color: white;
        text-decoration: none;
        cursor: pointer;
        animation-name: link-blip;
        animation-duration: 2s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
    }

    &__link {
        position: relative;
        transition: all 2s;

        .link__text {
            position: absolute;
            text-align: center;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 2.5rem;
            padding: 5rem 0;
            transition: opacity 2s;

            &--true {
                opacity: 1;
                z-index: 1;
            }
            &--false {
                opacity: 0;
                z-index: -1;
            }

            &--whatsapp {
                width: 300%;
            }
            &--linkedin {
                width: 200%;
            }
            &--email {
                width: 250%;
            }
            &--github {
                width: 290%;
            }
        }
    }

    &__logoBox {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        margin: 3rem auto 0;
    }

    &__logoItem {
        width: 10rem;
        transition: all 2s;

        &--tiny {
            width: 3rem;
        }

        &--small {
            width: 5rem;
        }

        &--medium {
            width: 8rem;
        }
        
        &--big {
            width: 13rem;
        }

        &--extra-big {
            width: 20rem;
        }

        &--huge {
            width: 30rem;
        }

        &--gone-right {
            transform: translateX(180%);
            opacity: 0;
        }
        &--returned-right {
            transform: translateX(0%);
            opacity: 1;
        }
        &--gone-left {
            transform: translateX(-180%);
            opacity: 0;
        }
        &--returned-left {
            transform: translateX(0%);
            opacity: 1;
        }
    }

    &__button {
        display: block;
        margin: 0 auto;
        background-color: transparent;
        border: none;
        outline: none;
        pointer-events: none;

        img {
            width: 100px;
            transition: width .5s;
        }

        .btn-enabled {
            pointer-events: initial;
            animation-name: catbtn-blip;
            animation-duration: 2s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;

            &:hover {
                cursor: pointer;
                animation: none;
            }
        }

        .btn-pressed {
            width: 65px;
        }
    }

    &__bulletList {
        padding-left: 4rem;
        line-height: 2.1rem;

        &--show {
            display: block;
        }

        &--hide {
            display: none;
        }
    }

    &__creditBox {
        display: table;
        width: 100%;
        position: relative;

        &--left {
            display: table-cell;
            vertical-align: top;
            width: 70%;
        }

        &--right {
            display: table-cell;
            text-align: center;
            width: 30%;
            vertical-align: top;
        }
    }

    &__creditItem {
        &:hover {
            cursor: default;
            color: darkred;
        }
    }

    &__creditImg {
        max-width: 200px;
        margin: 0 auto;
        
        &--show {
            display: block;
            position: sticky;
            top: 30px;
        }

        &--hide {
            display: none;
        }

        &--pic {
            box-shadow: 
                .5rem .5rem 4rem rgba(darkred, .5),
                -.5rem .5rem 4rem rgba(darkred, .5),
                0 -.25rem 4rem rgba(darkred, .5);
            border-radius: 10px;
        }
    }

    &__btnWrapper {
        position: absolute;
        bottom: 4rem;
        left: 50%;
        transform: translateX(-50%);
    }

    &__btn {
        font-family: "Source-Bold";
        text-transform: uppercase;
        padding: 1rem 3rem;
        color: black;
        background-color: white;
        border-radius: 2rem;
        min-width: 15rem;
        border: none;

        &:first-child {
            margin-right: 2rem;
        }

        &:hover:not(.laserbox__btn--show) {
            cursor: pointer;
            color: darkred;
            box-shadow: 
                0 .25rem 1.5rem darkred,
                .25rem 0 1.5rem darkred;
        }

        &--show {
            color: white;
            background-color: darkred;
        }
    }
}