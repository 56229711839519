/* Header Component */
@keyframes lightbar-move {
    0% {left: 0}
    50% {left: 100%}
    100% {left: 0}
}

@keyframes lightbar-freeze {
    0% {left: 0}
    100% {left: 600px}
}

@keyframes lightbar-fluctuation {
    0% {background: linear-gradient(black, darkred, black); width: 60%}
    15% {background: linear-gradient(black, darkred, black); width: 100%}
    30% {background: darkred; width: 60%}
    50% {background: red; width: 100%}
    70% {background: darkred; width: 60%}
    85% {background: linear-gradient(black, darkred, black); width: 100%}
    100% {background: linear-gradient(black, darkred, black); width: 60%}
}

/* WelcomeSeal Component */
@keyframes btn-shine {
    0% {box-shadow: none}
    50% {box-shadow: 20px 0 15px white, -20px 0 15px white;}
    100% {box-shadow: none}
}

@keyframes touchPoint-shine {
    0% {background-color: transparent; box-shadow: none}
    50% {background-color: white; box-shadow: 5px 0 1.5rem rgb(245, 245, 127), -5px 0 1.5rem rgb(245, 245, 127);}
    100% {background-color: transparent; box-shadow: none}
}

@keyframes touchPoint-tongue-roll {
    0% {height: 25%}
    50% {height: 60%}
    100% {height: 25%}
}

@keyframes touchPoint-tongue-roll-smallerScreen {
    0% {height: 40%}
    50% {height: 65%}
    100% {height: 40%}
}

/* VisitorQuestionnaire Component */
@keyframes catbtn-blip {
    0% {opacity: 1}
    25% {opacity: 0}
    50% {opacity: 1}
    75% {opacity: 0}
    100% {opacity: 1}
}

/* DisplaySection Component */
@keyframes elm-blip {
    0% {opacity: 1; color: orangered}
    25% {opacity: .2; color: black}
    50% {opacity: 1; color: orangered}
    75% {opacity: .2; color: black}
    100% {opacity: 1; color: orangered}
}

@keyframes prompt-blip {
    0% {opacity: 0}
    100% {opacity: 1}
}

@keyframes title-beat {
    0% {text-shadow: .5px 0 3px red, -.5px 0 3px red}
    50% {text-shadow: .75rem 0 2rem red, -.75rem 0 2rem red}
    100% {text-shadow: .5px 0 3px red, -.5px 0 3px red}
}

@keyframes lightbulb-blip {
    0% {background: linear-gradient(to bottom right, black, skyblue); background-clip: text; box-shadow: 0 0 0 transparent}
    25% {background: linear-gradient(to bottom right, red, darkorange); background-clip: text; box-shadow: -1px -1px 10px darkorange}
    50% {background: linear-gradient(to bottom right, black, skyblue); background-clip: text; box-shadow: 0 0 0 transparent}
    75% {background: linear-gradient(to bottom right, red, darkorange); background-clip: text; box-shadow: -1px -1px 10px darkorange}
    100% {background: linear-gradient(to bottom right, black, skyblue); background-clip: text; box-shadow: 0 0 0 transparent}
}

/* LaserBox Component */
@keyframes horizontal-laser-draw {
    0% {width: 0%}
    100% {width: 100%}
}

@keyframes vertical-laser-draw {
    0% {height: 0%}
    100% {height: 100%}
}

@keyframes laser-pointer-glow {
    0% {text-shadow: .5px 0 3px white, -.5px 0 3px white, 0 .5px 3px white, 0 -.5px 3px white}
    50% {box-shadow: .5rem 0 15px white, -.5rem 0 15px white, 0 .5rem 15px white, 0 -.5rem 15px white}
    100% {box-shadow: .5px 0 3px white, -.5px 0 3px white, 0 .5px 3px white, 0 -.5px 3px white}
}

@keyframes vertical-laser-box-glow {
    0% {box-shadow: 0 -.5px 3px red, 0 .5px 3px red}
    50% {box-shadow: 0 -.75rem 1rem red, 0 .75rem 1rem red}
    100% {box-shadow: 0 -.5px 3px red, 0 .5px 3px red}
}

@keyframes horizontal-laser-box-glow {
    0% {box-shadow: .5px 0 3px red, -.5px 0 3px red}
    50% {box-shadow: .75rem 0 1rem red, -.75rem 0 1rem red}
    100% {box-shadow: .5px 0 3px red, -.5px 0 3px red}
}

/* Pages common animations */
@keyframes content-appearing {
    0% {opacity: 0}
    100% {opacity: 1}
}

@keyframes content-disappearing {
    0% {opacity: 1}
    100% {opacity: 0}
}

@keyframes link-blip {
    0% {color: white}
    50% {color: darkred}
    100% {color: white}
}