.u-viewport-center {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translateX(50%);
}

.u-absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.u-relative-center {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.u-blockElm-h-center {
    text-align: center;
}

.u-imgElm-center {
    margin: 0 auto;
}

.u-flex {
    display: flex;
    align-items: center;

    &--center {
        justify-content: center;
    }
}

.u-hide {
    display: none;
}

.u-show {
    display: block;
}

.u-invisible {
    opacity: 0;
    animation-name: content-disappearing;
    animation-duration: 1s;
    animation-timing-function: linear;

    &--zombiecat {
        opacity: 0;
        animation-name: content-disappearing;
        animation-duration: 3s;
        animation-timing-function: linear;
    }
}

.u-visible {
    opacity: 1;
    animation-name: content-appearing;
    animation-duration: 1s;
    animation-timing-function: linear;

    &--zombiecat {
        opacity: 1;
        animation-name: content-appearing;
        animation-duration: 3s;
        animation-timing-function: linear;
    }
}

.u-goneAway {
    opacity: 0;
    
    &--down {
        transform: translateY(50vh);
    }

    &--left {
        transform: translateX(-50vw);
    }

    &--right {
        transform: translateX(30vw);
    }
}

.u-comeBack {
    opacity: 1;

    &--down {
        opacity: 1;
        transform: translateY(0vh);
    }

    &--right {
        transform: translateX(-50%);
    }
}

.u-margin-top-big {
    margin-top: 5rem;
}

.u-margin-top-extrabig {
    margin-top: 8rem;
}

.u-margin-bottom-regular {
    margin-bottom: 2rem;
}

.u-margin-bottom-big {
    margin-bottom: 5rem;
}

.u-margin-bottom-extrabig {
    margin-bottom: 8rem;
}

.u-margin-left-tiny {
    margin-left: .5rem;
}