*, 
*::after, 
*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    background-color: black;
    color: white;
    font-size: 10px;
}

html, body, #root, .App {
    position: relative;

    &::-webkit-scrollbar {
        background-color: transparent;
        width: .8rem;
    }

    &::-webkit-scrollbar-thumb {
        background: linear-gradient(to bottom, black, darkred, red, darkred, black);
        border-radius: 1rem;
    }
}

#root {
    overflow: auto;
}

main {
    font-family: "Source-Bold";
}

#main-page {
    margin: 5rem 0;
}

.btn-primary {
    border: .3rem solid white;
    border-radius: 2rem;
    background-color: transparent;
    color: white;
    font-family: "Source-Medium";
    padding: 1rem 2rem;
    margin: 2rem auto;
}
.btn-primary:hover {
    cursor: pointer;
    border: .3rem solid white;
    background-color: white;
    color: black;
}