.slider-section {
    height: 30vh;
    max-height: 20rem;
    max-width: $max-component-width;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    transition: opacity 4s;

    &--appearing {
        opacity: 1;
    }

    &--disappearing {
        opacity: 0;
    }

    input, output {
        display: block;
    }

    .slider__wrapper {
        width: 80%;
        text-align: center;

        input[type=range] {
            -webkit-appearance: none;
            height: .5rem;
            border-radius: 1rem;
            background: linear-gradient(to right, black, darkred, red, darkred, black);

            &::-webkit-slider-thumb {
                -webkit-appearance: none;
                background: #000;
                background-image: url("./../../images/icos/cat-paw-full-small.png");
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                height: 30px;
                width: 30px;
                padding: 1.5rem;
                border: 1px solid #fff;
                border-radius: 3rem;
            }

            &::-moz-range-thumb {
                -webkit-appearance: none;
                background: #000;
                background-image: url("./../../images/icos/cat-paw-full-small.png");
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                height: 30px;
                width: 30px;
                padding: 1.5rem;
                border: 1px solid #fff;
                border-radius: 3rem;
            }

            &::-ms-thumb {
                -webkit-appearance: none;
                background: #000;
                background-image: url("./../../images/icos/cat-paw-full-small.png");
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                height: 30px;
                width: 30px;
                padding: 1.5rem;
                border: 1px solid #fff;
                border-radius: 3rem;
            }
        } 

        .slider__input--big {
            width: 100%;
        }
    
        .slider__output {
            margin-top: 2rem;
            font-family: "Source-Bold";
            text-transform: uppercase;
            font-size: 2rem;
            font-style: italic;
            text-shadow: 0 .5rem 1rem white;
        }
    }
}
