.welcomeSeal {
    overflow: hidden;
    min-height: 65%;
    transition: all 1s;

    .welcomeSeal__heading {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;

        img {
            width: 50%;
            transition: all 2.5s;
        }
    }

    .welcomeSeal__touchPoint {
        position: absolute;
        height: 10%;
        width: 6%;
        background-color: transparent;
    
        &--p1 {
            top: 40%;
            left: 40%;
        }
    
        &--p2 {
            top: 40%;
            right: 40%;
        }
    
        &--p3 {
            top: 60%;
            left: 47%;
        }
    
        &:hover {
            cursor: pointer;
        }

        .touchPointCore {
            height: 35%;
            width: 25%;
            top: 50%;
            position: relative;
            left: 50%;
            transform: translate(-35%, 0);
            border-radius: 50%;
            border-top-left-radius: 30%;
            border-top-right-radius: 30%;
            animation-name: touchPoint-shine;
            animation-duration: 2s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
        }
        .touchPointCore--p1 {
            transform: translate(60%, 0);
        }
        .touchPointCore--p3 {
            transform: translate(-35%, -20%);
            animation-name: touchPoint-tongue-roll;
            background: linear-gradient(to bottom, rgb(241, 46, 78), rgb(240, 105, 128), rgb(234, 168, 179));
            width: 40%;
            height: 25%;

            @media only screen and (max-width: 1750px) {
                animation-name: touchPoint-tongue-roll-smallerScreen;
                height: 40%;
                transform: translate(-35%, -40%);
            }
        }
    }

    .welcomeSeal__subtitle {
        margin-top: .2rem;
    }

    .welcomeSeal__cta {
        height: 10rem;
        position: relative;
        transition: transform 2s, opacity .5s;
     
        .btn-shine {
             animation-name: btn-shine;
             animation-duration: 1s;
             animation-timing-function: linear;
        }
     }
}

.welcomeSeal--shrunk {
    position: relative;
    top: -5rem;
    left: -5rem;
    min-height: unset;

    .welcomeSeal__heading--shrunk img {
        width: 5rem;
    }

    .welcomeSeal__heading--shrunk h1,
    .welcomeSeal__heading--shrunk .welcomeSeal__touchPoint,  
    .welcomeSeal__subtitle--shrunk, 
    .welcomeSeal__cta--shrunk, 
    .languageSelector--shrunk {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .welcomeSeal {
        width: 100%;
    }
}