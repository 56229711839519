.header {
    position: fixed;
    width: 100%;
    background-color: #000;
    z-index: 100;
    top: 0;
    transition: all 1.5s;

    &--false {
        top: -10rem;
    }

    &--true {
        top: 0;
    }

    .header__upper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 1rem;

        .header__logo {
            margin-right: 5rem;

            &:hover {
                cursor: pointer;
            }
        }

        .header__linkList {
            display: flex;
            justify-content: space-evenly;
            align-items: center;

            .header__link {
                color: white;
                text-decoration: none;
                margin-right: 5rem;
                list-style-type: none;
                transition: all 1s;

                &:hover {
                    cursor: pointer;
                    transform: scale(1.5);
                    color: red;
                }

                &--active {
                    transform: scale(1.25);
                    color: darkred;
                }

                a {
                    color: white;
                    text-decoration: none;

                    &:hover {
                        cursor: pointer;
                        transform: scale(1.5);
                        color: red;
                    }
                }
            }

            .true {
                cursor: pointer;
                transform: scale(1.5);
                color: darkred;
            }
        }
    }

    .header__lower {

        .header__bar {
            height: .4rem;
            width: 10rem;
            background: black;
            position: relative;
            text-align: center;

            &--moving {
                animation-name: lightbar-move;
                animation-duration: 4s;
                animation-timing-function: linear;
                animation-iteration-count: infinite;
            }

            &--still {
                left: 600px;
            }

            .bar__contents {
                margin-top: 5px;
                padding: 1rem;

                &--moving {
                    width: 0;
                    opacity: 0;
                    background-color: transparent;
                    border: 0 solid darkred;
                    transition: all .5s;
                }

                &--still {
                    min-height: 23rem;
                    width: 300%;
                    opacity: 1;
                    background-color: darkred;
                    border: 3px solid red;
                    transition: all 1s;
                }

                .bar__linklist {
                    font-size: 2rem;
                    color: black;
                    text-decoration: none;
                    text-align: left;
                    list-style-type: none;

                    .linklist__category {
                        text-decoration: underline;
                        text-decoration-thickness: .5rem;
                        text-transform: uppercase;

                        &:not(.linklist__category:first-child) {
                            margin-top: 1.5rem;
                        }
                    }

                    .bar__link {
                        font-style: italic;
                        transition: all 1s;

                        &--first {
                            margin-top: .5rem;
                        }

                        a:link, 
                        a:visited, 
                        a:active {
                            color: black;
                            text-decoration: none;
                            text-align: left;
                            list-style-type: none;
                        }

                        a:hover {
                            cursor: pointer;
                            color: white;
                        }
                    }
                }
            }

            .bar__light {
                width: 100%;
                height: 100%;

                &--fluctuating {
                    animation-name: lightbar-fluctuation;
                    animation-duration: 2s;
                    animation-timing-function: linear;
                    animation-iteration-count: infinite;
                }

                &--still {
                    animation-name: none;
                    background: red;
                }

                &--off {
                    animation-name: none;
                    background: transparent;
                }
            }
        }
    }
}