.questionnaire {
    width: 50%;
    font-size: 24px;
    transform: translate(-100%, -50%);
    transition: all 1s 1s;

    .questionnaire__hint {
        font-family: "Source-Bold";
        margin: 0 auto 10px;
        text-align: center;
        max-width: 55rem;
    }

    .questionnaire__disclaimer {
        font-family: "Source-Medium";
        font-size: 1.2rem;
        margin: 0 auto 50px;
        text-align: center;
        max-width: 55rem;
    }

    .questionnaire__inputWrapper {
        width: 50%;
        margin: 0 auto 50px;

        .questionnaire__inputLabel, 
        .questionnaire__input {
            display: block;
        }

        .questionnaire__input {
            width: 100%;
            max-width: 40rem;
            margin: 0 auto;
            background-color: transparent;
            color: white;
            font-size: 14px;
            font-style: italic;
            font-family: "Source-Medium";
            border: none;
            outline: none;
            border-bottom: 1px solid white;
            padding: 20px 0;
        }

        .questionnaire__input:-webkit-autofill, 
        .questionnaire__input:-webkit-autofill:hover, 
        .questionnaire__input:-webkit-autofill:focus {
            border: none;
            border-bottom: 1px solid white;
            -webkit-text-fill-color: white;
            -webkit-box-shadow: 0 0 0px 1000px #000 inset;
            transition: background-color 5000s ease-in-out 0s;
        }
    }

    .questionnaire__submitBtn {
        background-color: transparent;
        border: none;
        outline: none;
        pointer-events: none;

        img {
            width: 100px;
            transition: all .5s;
        }
        .btn-enabled {
            pointer-events: initial;
            animation-name: catbtn-blip;
            animation-duration: 2s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;

            &:hover {
                cursor: pointer;
                animation: none;
            }
        }
        .btn-pressed {
            width: 65px;
        }
    }
}

.questionnaire--centered {
    transform: translate(50%, -50%);
}