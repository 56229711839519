.feature-section {
    background-color: #000;
    height: 19rem;
    max-height: 25rem;
    max-width: $max-component-width;
    box-shadow: $standard-box-shadow;
    border-radius: 3rem;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    transition: all 2s;
    padding: 0 2rem;
    margin-bottom: 15rem;

    &--appearing {
        opacity: 1;
    }

    &--disappearing {
        opacity: 0;
    }

    &--past {
        box-shadow: $past-box-shadow;
    }

    /* SLIDER SPECIFIC STYLES */
    .slick-slider {
        top: 50%;
        height: 15rem;
        transform: translateY(-50%);

        .slick-list {
            height: inherit;

            .slick-track {
                height: inherit;
                margin-left: 0;
                margin-right: 0;

                .slick-slide div {
                    height: inherit;
                    width: inherit;
                    margin-right: 2rem;
                    overflow: visible;

                    .slider-item {
                        display: flex !important;
                        flex-direction: column;
                        justify-content: center;
                        position: relative;
                        top: 50%;
                        text-align: center;
                        transform: translateY(-50%);
                        transition: all 2s;

                        .inactive {
                            opacity: .3;
                        }

                        .slider-clickableElement {
                            display: block;
                            overflow: visible;
                            position: relative;
                            height: 80%;
                            width: 70%;
                            margin: 0 auto;
                            transition: all .5s;
                            background-size: cover;
                            background-position: center;

                            &:hover {
                                transform: scale(1.2, 1.5);
                                cursor: pointer;
                            }

                            &--barcelona {
                                background-image: url("./../../images/icos/infoItems/barcelona.png");
                            }

                            &--manchester {
                                background-image: url("./../../images/icos/infoItems/manchester.png");
                            }

                            &--dubai {
                                background-image: url("./../../images/icos/infoItems/dubai.png");
                            }

                            .item-name {
                                font-size: 1.2rem;
                                font-style: italic;
                                text-transform: uppercase;
                                position: relative;
                                top: 80%;
                            }
                        }

                        .slider-clickableElement--imgWrapper {
                            display: block;
                            overflow: visible;
                            position: relative;
                            height: 80%;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            transition: all .5s;

                            &:hover {
                                transform: scale(1.5);
                                cursor: pointer;
                            }

                            img {
                                height: 5rem;
                            }

                            .item-name {
                                font-size: 1.2rem;
                                font-style: italic;
                                text-transform: uppercase;
                                position: relative;
                            }
                        }
                        
                        .slider-clickableElement--regular {
                            img {
                                height: 5rem;
                            }
                        }

                        .slider-clickableElement--big {
                            img {
                                height: 8rem;
                            }
                        }

                        .slider-clickableElement--extra-big {
                            img {
                                height: 10rem;
                            }
                        }
                    }
                }
            }
        }

        .slick-prev {
            left: -8.5rem;
            
            &::before {
                content: url("./../../images/icos/cat-paw-full-small.png");
                display: inline-block;
                transform: rotate(-90deg);
            }
        }

        .slick-next {
            right: -7.5rem;

            &::before {
                content: url("./../../images/icos/cat-paw-full-small.png");
                display: inline-block;
                transform: rotate(90deg);
            }
        }

        .slick-disabled::before {
            content: url("./../../images/icos/cat-paw-hollow-small.png");
            opacity: 1;
        }

        .slick-dots {
            bottom: -8rem;

            button::before{
                border: 2px solid white;
                border-radius: 1rem;
                height: 1rem;
                width: 1rem;
            }

            .slick-active button::before{
                background-color: white;;
            }
        }
    }
}