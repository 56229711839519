@font-face {
    font-family: "Russo-Regular";
    src: url("./../../fonts/RussoOne-Regular.ttf");
}

@font-face {
    font-family: "Source-Medium";
    src: url("./../../fonts/SourceCodePro-Medium.ttf");
}

@font-face {
    font-family: "Source-Bold";
    src: url("./../../fonts/SourceCodePro-Bold.ttf");
}

.branding-heading {
    font-family: "Russo-Regular";
    font-style: italic;
    font-size: 3rem;
    text-shadow: 
        3rem 1rem 4rem rgba(darkgrey, .5),
        -3rem 1rem 4rem rgba(darkgrey, .5),
        0 -.25rem 4rem rgba(darkgrey, .5);
}

.bullet-point--none {
    list-style-type: none;
}

.bullet-point--green {
    color: green;
}

.bullet-point--red {
    color: red;
}